@import "../../styles/main.scss";
@import "../../styles/variables.scss";

.heading {
  @extend .heading;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 0 18% 10%;
  width: 64%;

  // @media (min-width: 48rem) and (max-width: 64rem) {
  //   margin: 0 %;
  //   width: 80%;
  // }

  @media (min-width: 20rem) and (max-width: 48rem) {
    flex-direction: column-reverse;
    margin: 0 3%;
    width: 94%;
  }
}

.picture {
  width: 100%;
  height: auto;
}

.picDiv {
  flex: 1;
}

.content {
  flex: 1;
}

.about {
  margin: 10px 0 0 40px;

  @media (min-width: 20rem) and (max-width: 48rem) {
    margin: 30px 0;
  }
}

.subHeading {
  @extend .subHeading;

  @media (min-width: 20rem) and (max-width: 48rem) {
    text-align: center;
  }
}

.text {
  @extend .text;

  font-family: din-next-w01-light, din-next-w02-light, din-next-w10-light,
    sans-serif;
  line-height: 24px;
  margin-top: 50px;
  text-align: justify;
}

.connectBtn {
  color: #2aa8cc;
  cursor: pointer;
}
